import React from 'react';
import logo from '../../assets/logo.svg';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { setUserDataAction } from '../../store';
import { Button, TextField } from '@material-ui/core';
import { ButtonLoading } from '../Buttons';
import firebase from '../../Firebase';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';


const db = firebase.firestore();


const styles = (theme) => ({
  pinContainer: {
    position: 'absolute',
    top: '20%',
    left: '1000%',
    width: '80%',
    transform: 'translateX(-50%)',
    opacity: '0',
    transition: 'opacity 1s 0.5s',

    padding: `${theme.spacing.unit * 4}px 10%`,
    '& > [role=button]': {
      width: '100%',
    },

    '.started &': {
      opacity: '1',
      left: '50%',
    },
  },

  actionGroup: {
    '& > *': {
      marginBottom: '40px',
    },
  },

  affirmationContainer: {
    transform: 'scaleY(0)',
    transition: 'all 2s',

    '&.open': {
      transform: 'scaleY(1)',
    }
  },

  pinInput: {
    background: 'white',
    borderRadius: '5px',
    border: '0',

    '& input': {
      textAlign: 'center',
      fontSize: '1.5rem',
    }
  },

  container: {
    '&.started': {

    }
  },

  logo: {
    width: '160px',
    height: 'auto',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    transition: 'all 0.5s',

    '.started &': {
      width: '80px',
      top: '80px',
    }
  },

  start: {
    position: 'absolute',
    top: '63%',
    left: '50%',
    transform: 'translateX(-50%)',
    transition: 'all 0.5s',

    '.started &': {
      opacity: '0',
    }
  }
});


class Home extends React.Component {
  constructor(props) {
    super(props);

    const pin = this.props.match.params.pin;

    this.state = {
      started: false,
      pin: pin && pin.length === 4 ? pin : '',
      success: false,
      loading: false,
      hasPINBeenChecked: false,
      isPINValid: false,
      isShowingHelp: false,
      hasCompletedProfile: true,
    };

    this.loadDetails = this.loadDetails.bind(this);
    this.onPINChange = this.onPINChange.bind(this);
    this.showHelp = this.showHelp.bind(this);
  }

  componentDidMount() {
    if (this.state.pin) {
      this.onPINChange(this.state.pin);
      this.loadDetails();
    }
  }

  onPINChange(event) {
    let value = event && event.target && event.target.value ? event.target.value : '';
    this.setState( { pin: value }, () => console.log(this.state) );
  }

  loadDetails() {
    this.setState( { loading: true },
      () => {
        db
          .collection('users')
          .where('pin', '==', parseInt(this.state.pin))
          .get()
          .then( (snapshot) => {
            if (!snapshot.empty) {
              const userData = snapshot.docs[0].data();

              userData.answers = JSON.parse(userData.answers || '{}');

              this.props.setUserData({ ...userData, userDocRef: snapshot.docs[0].id });

              this.setState({ isPINValid: true, hasPINBeenChecked: true, dbId: snapshot.docs[0].id, userData: snapshot.docs[0].data(), loading: false, hasCompletedProfile: userData.completed });

              if (!userData.completed) {
                this.props.history.push('/details');
              }
            }
            else {
              this.setState({ isPINValid: false, hasPINBeenChecked: true, loading: false });
            }
          })
      });
  }

  showHelp() {
    this.setState({ isShowingHelp: true });
  }

  render() {
    const { classes } = this.props;
    const { started, success, loading, hasPINBeenChecked, isPINValid, isShowingHelp, userData } = this.state;


    const confirmation = userData && userData.name ? <h2>Hi {userData.name}</h2> : <span />;
    const isValidUser = isPINValid && hasPINBeenChecked;
    const isUserInvalid = !isPINValid && hasPINBeenChecked;

    const containerClasses = classNames(
      classes.container,
      { 'started': started },
    );

    return (
      <div className={containerClasses}>
        <img src={logo} className={classes.logo} alt={"Yours Truly"}/>

        <h2 className={classes.start}>
          <div className="fade" onClick={() => this.setState( { started: true } )}>Start</div>
        </h2>

        <div className={classes.pinContainer}>
          <form autoComplete="off">
            {
              !isValidUser &&
              <React.Fragment>
                <h2>Enter the pin found on your sticker to get started</h2>
                {/*<ReactCodeInput
              fields={4}
              type={"number"}
              onChange={this.onPINChange}
              value={this.state.pin}
            />*/}
                <InputMask mask="9999"
                           maskChar="_"
                           value={this.state.pin}
                           onChange={this.onPINChange}>
                  {() => <TextField id="pin"
                                    name="pin"
                                    margin="normal"
                                    type="text"
                                    className={classes.pinInput}/>}
                </InputMask>
                <ButtonLoading
                  variant={"contained"}
                  color={"primary"}
                  onClick={this.loadDetails}
                  loading={loading}
                  success={success}
                  label={"Next"}
                  disabled={this.state.pin.length !== 4}
                  type={"submit"}
                  onFocus="blur();"
                />
                {
                  isUserInvalid &&
                  <h3>Sorry, we can't find that PIN. Please check that you have entered the correct PIN or find someone from Portable to assist.</h3>
                }
              </React.Fragment>
            }
            {
              isValidUser && isShowingHelp &&
              <React.Fragment>
                <h2>Please ask a member of the Portable team for help and then try again</h2>
                <Button variant={"contained"}
                        color={"primary"}
                        component={Link}
                        to={"/"}>Retry</Button>
              </React.Fragment>
            }
            {
              isValidUser && !isShowingHelp &&
              <React.Fragment>
                {confirmation}<br />
                <div className={classes.actionGroup}>
                  <Button variant={"contained"}
                          color={"primary"}
                          component={Link}
                          to={"/details"}>Update your details</Button>
                  <Button variant={"contained"}
                          color={"primary"}
                          component={Link}
                          to={"/profile"}>View your profile</Button>
                </div>
              </React.Fragment>
            }
          </form>
        </div>
      </div>
    );
  }
}


/*
class Home extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.container}>
          <img src={logo} className={classes.logo} alt={"Yours Truly"}/>

          <h2 className={classes.start}>
            <Link className="fade" to="/pin">Start</Link>
          </h2>
        </div>
      </div>
    );
  }
}
*/

const mapStateToProps = (state) => ({
  userData: state.app.userData,
});

const mapDispatchToProps = (dispatch) => ({
  setUserData: (data) => dispatch(setUserDataAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home));