import React from 'react';
import { connect } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const styles = (theme) => ({
  progress: {
    position: 'absolute',
    top: '24px',
    left: '32px',
    right: '32px',
  }
});


class Progress extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <LinearProgress variant="determinate" value={(this.props.questionProgress.current + 1) * 100 / (this.props.questionProgress.total)} className={classes.progress}/>
    );
  }
}

const mapStateToProps = (state) => ({
  questionProgress: state.app.questionProgress,
});

export default connect(mapStateToProps)(withStyles(styles)(Progress));