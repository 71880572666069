import { combineReducers, createStore } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from "redux-persist";
// import { createLogger } from 'redux-logger';
import { createAction } from 'redux-actions';
import merge from 'deepmerge';


// @todo replace this
// const isProd = false;


const initialState = {
  userData: {
    name: '',
    email: '',
  },
  modalsViewed: { },
  questionProgress: {
    current: 0,
    total: 0,
  },
};


const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_DATA':
      return merge(state, { userData: {...state.userData, ...action.payload } });
    case 'UPDATE_ANSWER':
      return merge(state, { userData: { answers: merge(state.userData.answers, action.payload) } });
    case 'SET_MODAL_VIEWED':
      return merge(state, { modalsViewed: { ...state.modalsViewed, ...action.payload } } );
    case 'SET_QUESTION_PROGRESS':
      return merge(state, { questionProgress: { ...state.questionProgress, ...action.payload} } );
    default:
      return state;
  }
};

const setUserDataAction = createAction('SET_USER_DATA');
const updateAnswersAction = createAction('UPDATE_ANSWER');
const setModalsViewedAction = createAction('SET_MODAL_VIEWED');
const setQuestionProgressAction = createAction('SET_QUESTION_PROGRESS');


/*
const Logger = createLogger({
  diff: true,
  collapsed: (getState, action, logEntry) => !logEntry.error
});
*/


const appPersistConfig = {
  key: 'app',
  storage,
  blacklist: [ 'modalsViewed', 'questionProgress' ]
};


var reducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
});

let __INIT_STORE__ = {};

// reducer = persistReducer(persistConfig, reducer);


/* eslint-disable no-underscore-dangle */
// const composeEnhancers = (isProd ? null : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable no-underscore-dangle */


const store = createStore(reducer,
  __INIT_STORE__,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  //     composeEnhancers(thunkMiddleware),
);


const persistor = persistStore(store);

// persistor.purge();


export { store, persistor, appReducer, setUserDataAction, updateAnswersAction, setModalsViewedAction, setQuestionProgressAction };