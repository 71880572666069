import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      body2: {
        fontFamily: 'Nocturno, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"',
      },
    },
    MuiButton: {
      root: {
        backgroundColor: 'rgba(255, 255, 255, .6)',
        borderRadius: '14px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
        color: '#000000',
        lineHeight: '28px',
        fontSize: '26px',
        fontFamily: 'Nocturno, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"',
        textTransform: 'lowercase',
      },
      containedPrimary: {
        backgroundColor: '#f8f5ff',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
        color: '#000000',
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .6) !important',
            color: '#000000',
          }
        }
      },
    },
    MuiIconButton: {
      root: {
        padding: '9px',
      },
      label: {
        '& img': {
          height: '30px',
        }
      }
    },
    MuiFormControl: {
      root: {
        marginTop: '12px',
        width: '100%',
      },
    },
    MuiInput: {
      root: {
        fontSize: '1.2rem',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '1.5rem',
      },
      shrink: {
        transform: 'translate(0, 1.5px) scale(0.6)',
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '20px',
      }
    },
    MuiLinearProgress: {
      root: {
        height: '2px',
      },

      colorPrimary: {
        backgroundColor: '#B19DDF',
      },
      barColorPrimary: {
        backgroundColor: 'black',
      },
      bar1Determinate: {
        transitionTimingFunction: 'ease-out'
      }
    }
  },
});

const wrapApp = (AppComponent, reduxStore) => {
  return (
    <Provider store={reduxStore}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <AppComponent/>
          </MuiThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};


ReactDOM.render(wrapApp(App, store), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
