import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';


const styles = () => ({
  bg: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    overflow: 'hidden',
  },
  appBackground: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    filter: 'blur(50px)',
    transition: 'all 1s',
  },
  bubble: {
    width: '900px',
    height: '900px',
    position: 'absolute',
    borderRadius: '50%',
    left: '-20%',
    top: '-20%',
    transition: 'all 3s',
    transform: 'translate(-50%, -50%)',
  },
  inner1: {
    width: '100%',
    height: '100%',
    transformOrigin: '60% 60%',
    animation: 'rotate 15s linear infinite running',
  },
  inner2: {
    width: '100%',
    height: '100%',
    animation: 'scale 11s ease-in-out infinite running',
  },
  appContainer: {
    zIndex: '10',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  },
});

class Background extends React.Component {
  constructor(props) {
    super(props);

    const stylesArray = [
      {
        bg: {
          backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },

        bubble1: {
          top: '-20%',
          left: '-10%',
        },

        bubble2: {
          top: '100%',
          left: '80%',
        },
      },
      {
        bg: {
          backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },

        bubble1: {
          top: '90%',
          left: '90%',
        },

        bubble2: {
          top: '0%',
          left: '30%',
        },
      },
      {
        bg: {
          backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },

        bubble1: {
          top: '-20%',
          left: '30%',
        },

        bubble2: {
          top: '70%',
          left: '90%',
        },
      },
      {
        bg: {
          backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },

        bubble1: {
          top: '-10%',
          left: '10%',
        },

        bubble2: {
          top: '60%',
          left: '90%',
        },
      },
      {
        bg: {
          backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },

        bubble1: {
          top: '95%',
          left: '15%',
        },

        bubble2: {
          top: '0%',
          left: '80%',
        },
      },
      {
        bg: {
          backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },

        bubble1: {
          top: '15%',
          left: '90%',
        },

        bubble2: {
          top: '90%',
          left: '15%',
        },
      }
    ];

    const homeStyles = {
      bg: {
        backgroundColor: 'white'
      },

      bubble1: {
        top: '-10%',
        left: '-10%',
      },

      bubble2: {
        top: '100%',
        left: '80%',
      },
    };

    this.state = {
      styleIndex: Math.floor(Math.random() * stylesArray.length),
      stylesArray: stylesArray,
      homeStyles: homeStyles,
    };


    // setInterval( () => this.setState( { styleIndex: (this.state.styleIndex + 1) % this.state.stylesArray.length }, () => console.log('Maybe remove this', this.state.styleIndex, this.state.stylesArray[this.state.styleIndex]) ), 3000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState( { styleIndex: (this.state.styleIndex + 1) % this.state.stylesArray.length } );
    }
  }

  render() {
    const { classes, children } = this.props;
    const { homeStyles, stylesArray, styleIndex } = this.state;

    var bubble1Inner = {
      background: 'radial-gradient(495.00px at 50% 50%, #DFA59D 0%, rgba(255, 255, 255, 0) 100%)',
      animationDelay: '0s',
    };
    var bubble2Inner = {
      background: 'radial-gradient(483.00px at 50% 50%, #e8e0fb 0%, rgba(255, 255, 255, 0) 100%)',
      animationDelay: '3s',
    };


    const styles = (this.props.location.pathname === '/') ? homeStyles : stylesArray[styleIndex];
    /*
        switch (test) {
          case '/question/1':
            bg.backgroundColor = 'white';

            bubble1.top = '0%';
            bubble1.left = '110%';

            bubble2.top = '100%';
            bubble2.left = '-5%';

            break;

          case '/question/2':
            bg.backgroundColor = 'white';

            bubble1.top = '90%';
            bubble1.left = '80%';

            bubble2.top = '10%';
            bubble2.left = '100%';

            break

          case '/question/3':
            bg.backgroundColor = 'white';

            bubble1.top = '50%';
            bubble1.left = '30%';

            bubble2.top = '70%';
            bubble2.left = '90%';

            break;

          default:
            bg.backgroundColor = '#ebe3fb';

            break;
        }
        */

    return (
      <div className={classNames(classes.bg, 'app-bg-container')}>

        <div className={classes.appBackground} style={styles.bg}>
          <div className={classes.bubble} style={styles.bubble1}>
            <div className={classes.inner1}
                 style={{ animationDirection: 'reverse', animationDelay: '8s' }}>
              <div className={classes.inner2} style={bubble1Inner}/>
            </div>
          </div>
          <div className={classes.bubble} style={styles.bubble2}>
            <div className={classes.inner1}>
              <div className={classes.inner2} style={bubble2Inner}/>
            </div>
          </div>
        </div>

        <div className={classes.appContainer}>
          {children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hello: state.app.hello,
});

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Background)));