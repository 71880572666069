import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import CheckIcon from '@material-ui/icons/Check';


const buttonLoadingStyles = (theme) => ({
  wrapper: {
    justifyContent: 'center',
    display: 'flex',
    margin: `${theme.spacing.unit * 4}px 0`,
    position: 'relative',
  },
  buttonBase: {
    minHeight: '44px',
  },
  buttonSuccess: {},
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class ButtonLoading extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSuccess: false,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if ( (!prevProps.success && prevProps.loading) && this.props.success) {
      this.setState({ showSuccess: true },
        () => setTimeout( () => this.setState({ showSuccess: false}), 2000)
      );
    }
  }

  render() {
    const { classes, loading = false, onClick, label, disabled, ...rest } = this.props;
    const buttonClassname = classNames({
      [classes.buttonBase]: true,
    });

    return (
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          className={buttonClassname}
          disabled={loading || disabled}
          onClick={onClick}
          {...rest}
        >
          {loading ? ' ' : (label || 'Go')}
        </Button>
        {this.state.showSuccess && <CheckIcon/>}
        {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
      </div>
    );
  }
}

ButtonLoading = withStyles(buttonLoadingStyles)(ButtonLoading);

export { ButtonLoading };