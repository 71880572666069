import React from 'react';
import './App.css';
import Home from './components/pages/Home';
import Background from './components/Background';
import Question from './components/Question';
import { Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { withRouter } from 'react-router-dom';
import Thankyou from './components/pages/Thankyou';
import Details from './components/pages/Details';
import Profile from './components/pages/Profile';
import Stats from './components/pages/Stats';
import Progress from './components/Progress';

const duration = 500;


class App extends React.Component {
  render() {
    return (
      <Background>
        <div className="App">
          {( this.props.location.pathname.includes('question') ) &&
            <Progress key={this.props.location.key}/>
          }
          <TransitionGroup style={{ minHeight: '400px' }}
                           appear={true}
                           className={"page-transition-container"}>
            <CSSTransition className={"page-transition"}
                           classNames={"page-transitions"}
                           timeout={duration}
                           key={this.props.location.key}>
              <Switch location={this.props.location}>
                <Route path="/details" component={Details}/>
                <Route path="/profile:person?" component={Profile}/>
                <Route path="/question/:question" component={Question}/>
                <Route path="/thankyou" component={Thankyou}/>
                <Route path="/stats/:password" component={Stats}/>
                <Route path="/:pin?" component={Home}/>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </Background>
    );
  }
}

export default withRouter(App);
