import React from 'react';
import { Redirect } from 'react-router-dom';


class Stats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: 'da838af',
      passwordParam: this.props.match.params.password || '',
    };
  }
  render() {
    if (this.state.password === this.state.passwordParam) {
      return (
        <div>
          <h1>Stats</h1>
        </div>
      );
    }
    else {
      return <Redirect to={"/"} />;
    }
  }
}

export default Stats;