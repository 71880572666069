import * as firebase from 'firebase/app';
import 'firebase/firestore';


var config = {
  apiKey: "AIzaSyD8M1nc-dCgSnJ6620xhduBYWCc1EFfFMA",
  authDomain: "design-your-death.firebaseapp.com",
  databaseURL: "https://design-your-death.firebaseio.com",
  projectId: "design-your-death",
  storageBucket: "design-your-death.appspot.com",
  messagingSenderId: "932229838011"
};
firebase.initializeApp(config);

const firestore = firebase.firestore();
const settings = {/* your settings... */ timestampsInSnapshots: true};
firestore.settings(settings);

export default firebase;