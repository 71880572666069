import React from 'react';
import * as d3 from 'd3';
import { connect } from 'react-redux';


d3.selection.prototype.moveToFront = function () {
  return this.each(function () {
    this.parentNode.appendChild(this);
  });
};

function wrap(text, width) {
  text.each(function () {
    var text = d3.select(this),
      words = text.text().split(/\s+/).reverse(),
      word,
      line = [],
      lineNumber = 1,
      lineHeight = 1.4, // ems
      y = text.attr("y"),
      dy = parseFloat(text.attr("dy")) - 0.5,
      dx = text.attr('dx'),
      tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
      if (dx !== '') {
        tspan.attr('dx', dx);
      }
      let first = tspan;
    // eslint-disable-next-line
    while (word = words.pop()) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text.append("tspan")
          .attr("x", (dx === null) ? 0 : dx)
          .attr("y", y)
          .attr("dy", lineNumber++ * lineHeight + dy + "em")
          .text(word);

        lineNumber++;
      }

      if (lineNumber === 1) {
        tspan.attr('dy', '0.3em');
      }
      else {
        first.attr('dy', '-0.3em');
      }
    }
  });
}

class Graph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      question: null,
      numQuestions: null,
      questionType: null,
      ready: false,
    };

    setTimeout( () => this.setState( { ready: true }, () => setTimeout(this.initGraph.bind(this), 50) ), 500);

    this.initGraph = this.initGraph.bind(this);
    this.generateRandomSize = this.generateRandomSize.bind(this);
    this.shuffle = this.shuffle.bind(this);

    this.randy = Math.floor(Math.random() * 500);
  }

  generateRandomSize(size, spread) {
    return Math.random() * (spread * 2) - spread + size;
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  initGraph() {
    this.w = window.innerWidth;
    this.h = window.innerHeight * 0.7;

    this.pack = data => d3.pack()
      .size([this.w - 10, this.h - 10])
      .padding(-10)(d3.hierarchy(data)
        .sum(d => d.size));

    this.isApple = !!navigator.platform && /iPad|iPhone|iPod|Safari/.test(navigator.platform);

    console.log(this.isApple);




    this.vis = d3.select(this.chart)
      .select("svg.container" + this.randy)
      .select('g.buttonContainer')
      .append("svg:g")
      .attr("transform", "translate(2, 2)");

    this.radialGrad = this.vis
      .append('defs')
      .append('radialGradient')
      .attr('id', 'gradient3');

    this.radialGrad
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', '#B19DDFFF')
      .attr('stop-opacity', '1');

    this.radialGrad
      .append('stop')
      .attr('offset', '30%')
      .attr('stop-color', '#B19DDFFF')
      .attr('stop-opacity', '1');;

    this.radialGrad
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#B19DDF00')
      .attr('stop-opacity', '0');;



    d3.json('/questions.json')
      .then((json) => {
        var question;

        question = json.find( question => question.id === this.props.questionId);

        // randomise the size a bit and add in some invisible circles to space things out
        question.children = [
          ...question.children.map((answer) => ({
            ...answer,
            oldSize: answer.size,
            size: this.generateRandomSize(answer.size, 3)
          })),
        ];

        for (var i = question.children.length; i <= 8; i++) {
          question.children.push({ "name": "", "path": "", "size": 1, "type": "empty" });
        }

        question.children = this.shuffle(question.children);

        question.children.sort((a, b) => {
          if (a.type === 'primary' && b.type !== 'primary') {
            return 1;
          }

          return -1;
        });

        question.title = question.title.replace('{name}', this.props.userData.name);

        this.root = this.pack(question);

        this.setState({ question: question, numQuestions: json.length, questionType: question.type });
        this.props.updateQuestionData({ question: question, numQuestions: json.length, questionType: question.type });

        if (question.type === 'radio' || question.type === 'rating') {
          this.node = this.vis.selectAll("g")
            .data(this.root.leaves())
            .enter()
            .append("svg:g")
            .attr("class", function (d) {
              var type = (d.data.type === 'primary') ? 'primary' : (d.data.type === 'secondary' ? 'secondary' : 'empty');
              return d.children ? "node " + type : "leaf node " + type;
            })
            .attr("transform", function (d) {
              return "translate(" + d.x + "," + d.y + ")";
            })
            .append("svg:g")
            .attr('class', 'buttonInner');


          // add masks for all visible nodes
          this.node
            .filter(function (d) {
              return d.data.type !== 'empty'
            })
            .append("svg:circle")
            .attr('class', 'expander')
            .style('fill', 'url(#gradient3)')
            .attr("r", '0');


          this.node
            .append("svg:circle")
            .attr('class', 'target')
            .attr('id', (d, i) => `circle-${i}`)
            .attr("r", function (d) {
              return d.r;
            });


          // text
          this.node
            .filter(function (d) {
              return !d.children;
            })
            .append('svg:g')
            .attr('class', 'textContainer')
            .append("svg:text")
            .attr("text-anchor", "middle")
            .attr("dy", ".3em")
            .text(function (d) {
              return d.data.name.substring(0, d.r / 2);
            })
            .call(wrap, 100);

          this.node
            .select('text')
            .style("font-size", function(d) { return Math.min(28, (2 * d.r - 8) / this.getComputedTextLength() * 16) + "px"; });


          if (question.type === 'rating') {
            this.ratingText = this.vis
              .append('svg:text')
              .attr('text-anchor', 'left')
              .attr('font-size', '22px')
              .attr('dx', '32px')
              .attr('dy', '0.4em')
              .text(question.options[this.props.currentRatingId].title);

            this.ratingText
              .call(wrap, window.innerWidth - (32 * 2));

            this.ratingText
              .style('opacity', '0')
              .transition()
              .duration(1500)
              .style('opacity', '1');
          }

          // add enter transition to nodes
          this.node
            .filter(function (d) {
              return d.data.type !== 'empty'
            })
            .transition()

            .delay((d, i) => {
              if (question.type === 'rating') {
                return 300 + i * 200;
              }
              else {
                return 1300 + i * 200;
              }
            })
            .duration(1000)
            .style('opacity', '1')
            .style('transform', 'scale(1)');


          let _this = this;

          // primary node onclick
          this.node
            .filter((d) => {
              return d.data.type === 'primary'
            })
            .on('click', function (d, i) {
              // move node to stop of stack so it draws on top of everything else
              d3
                .select(this.parentNode)
                .moveToFront();

              d3
                .select(this)
                .select('circle.expander')
                .transition()
                .ease(d3.easeCircleOut)
                .duration(2000)
                .attr('r', (window.innerWidth * 2) + 'px');


              if (question.type === 'rating') {
                _this.node
                  .transition()
                  .duration(2000)
                  .style('opacity', '0')
                  .remove();

                _this.ratingText
                  .transition()
                  .duration(1500)
                  .style('opacity', '0')
                  .remove();
              }

              setTimeout(() => _this.initGraph(), 1000);

              _this.setState({ navFade: true });

              setTimeout(() => {
                if (question.type === 'rating') {
                  _this.props.onRatingChange(_this.props.currentRatingId, d.data.id);
                }
                else {
                  _this.props.onDone(d.data.id);
                }
              }, 20);
            });


          // secondary node onclick
          this.node
            .filter((d) => {
              return d.data.type === 'secondary'
            })
            .on('click', (d, i) => {
              this.props.openModal(d.data.id);
            });


          if (question.showInfo && !this.props.modalsViewed[question.id]) {
            this.node
              .each((d) => {
                if (d.data.type === 'secondary') {
                  setTimeout( () => this.props.openModal(d.data.id), 3500);
                }
              });
          }
        }
      });
  }

  render() {
    if (this.state.ready) {
      return (
        <div id="questions"
             className={"q-" + this.state.questionType}
             ref={(ref) => this.chart = ref}>
          <svg className={"container container" + this.randy}>
            <g className="buttonContainer"/>
          </svg>
        </div>
      );
    }
    else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  modalsViewed: state.app.modalsViewed,
});

export default connect(mapStateToProps)(Graph);