import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import classNames from 'classnames';


const styles = (theme) => ({
  nav: {
    position: 'absolute',
    left: '0',
    right: '0',
    bottom: '0',
    height: '40px',
    fontSize: '24px',
    color: 'black',
    zIndex: '100',
    transition: 'all 1s',

    '& a:visited': {
      color: 'black',
    },
  },
  Link: {
    position: 'absolute',
    bottom: '20px',
    textDecoration: 'none',
  },
  prev: {
    left: '20px',
  },
  next: {
    right: '20px',
  },
  fade: {
    opacity: '0.2',
  },
});


class Nav extends React.Component {
  render() {
    const { classes, prevLink, nextLink, prevLabel = 'Back', nextLabel = 'Skip', fade = false } = this.props;

    const classList = classNames(
      classes.nav,
      { [classes.fade]: fade },
    );

    return (
      <div className={classList}>
        <Link className={classNames(classes.Link, classes.prev)} to={prevLink()}>
          {prevLabel}
        </Link>
        <Link className={classNames(classes.Link, classes.next)} to={nextLink}>
          {nextLabel}
        </Link>
      </div>
    );
  }
}

export default withStyles(styles)(Nav);