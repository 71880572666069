import React from 'react';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import firebase from '../../Firebase';
import { ButtonLoading } from '../Buttons';
import { setUserDataAction } from '../../store';
import hideVirtualKeyboard from 'hide-virtual-keyboard';

const db = firebase.firestore();


class Details extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: this.props.userData || {},
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.updateField = this.updateField.bind(this);
  }

  onFormSubmit(e) {
    e.preventDefault();

    hideVirtualKeyboard();

    this.setState( { loading: true } );

    db
      .collection('users')
      .doc(this.props.userData.userDocRef)
      .update({
        name: this.state.userData.name,
        email: this.state.userData.email,
      })
      .then( () => {
        this.props.setUserData(this.state.userData);

        this.props.history.push('/question/1');
      });
  }

  updateField(field, value) {
    const userData = Object.assign({}, this.state.userData, { [field]: value });
    this.setState({ userData: userData }, () => console.log(this.state));
  }

  render() {
    const { classes } = this.props;
    const { userData = {}, loading } = this.state;

    return (
      <div className={classes.container}>
        <h2>Your Information</h2>

        <form autoComplete="true">
          <TextField
            label={"Name"}
            name={"name"}
            type={"text"}
            value={userData.name}
            onChange={(e) => this.updateField('name', e.target.value)}
          />

          <br />

          <TextField label={"Email (optional)"}
                     name={"email"}
                     type={"email"}
                     value={userData.email}
                     onChange={(e) => this.updateField('email', e.target.value)}
          />

          <ButtonLoading
            label={"Next"}
            onClick={this.onFormSubmit}
            type={"submit"}
            loading={loading}
          />
        </form>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    padding: `${theme.spacing.unit * 4}px`,
  },
});

const mapStateToProps = (state) => ({
  userData: state.app.userData,
});

const mapDispatchToProps = (dispatch) => ({
  setUserData: (data) => dispatch(setUserDataAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Details));