import React from 'react';
import { FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import IconChecked from '../assets/checkbox-checked.svg';
import IconUnchecked from '../assets/checkbox-unchecked.svg';


const styles = (theme) => ({
  container: {
    width: '100%',
    height: '50vh',
    marginTop: '80px',
    fontFamily: 'serif',
    padding: '40px',
    overflow: 'auto',
  },
});


const Checkbox = (props) => {
  return (
    <MuiCheckbox
      {...props}
      icon={props.icon || (
        <img
          style={{
          }}
          src={IconUnchecked}
          alt="Unticked"
        />)}
      checkedIcon={props.checkedIcon || (<img style={{}} src={IconChecked} alt="Ticked"/>)}
    />
  );
};


class Checkboxes extends React.Component {
  render() {
    const { classes, answers, handleChange, checkboxValues } = this.props;

    return (
      <div className={classes.container}>
        {answers.filter(answer => answer.type === 'primary').map(answer => (
          <div key={answer.id}>
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  checked={checkboxValues[answer.name]}
                  onChange={handleChange(answer.name)}
                  value={answer.name}
                  name={answer.name}
                  color="primary"
                />
              }
              label={answer.name}
            /><br/>
          </div>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(Checkboxes);