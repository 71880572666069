import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Nav from './Nav';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Lettering from './Lettering';
import Graph from './Graph';
import Checkboxes from './Checkboxes';
import { setModalsViewedAction, setQuestionProgressAction, updateAnswersAction } from '../store';
import firebase from '../Firebase';
import ReactBody from 'react-body';
import Input, { pageTransitionTime } from './Input';


const db = firebase.firestore();


const styles = () => ({
  container: {
    padding: '32px',
    textAlign: 'left',
  },
  answerContainer: {
    position: 'absolute',
    left: '0',
    right: '0',
    bottom: '0',
    top: '0',
    zIndex: 100,
  },
});


const customStyles = {
  content: {
    background: '#D2C0F9',
    transform: 'none',
    left: '0',
    top: '0',
    right: '0',
    bottom: '0',
  }
};

Modal.setAppElement('#root');


class Question extends React.Component {
  constructor(props) {
    super(props);

    const questionId = parseInt(this.props.match.params.question);

    this.state = {
      question: [],
      questionId: questionId,
      init: false,
      clipPaths: [],
      modalIsOpen: false,
      modal: {},
      numQuestions: null,
      navFade: false,
      questionType: null,
      checkboxValues: [],
      inputValues: [],
      ratingValues: {},
      isModalClosing: false,
      currentRatingQuestion: 0,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getPrevLink = this.getPrevLink.bind(this);
    this.getNextLink = this.getNextLink.bind(this);
    this.updateQuestionData = this.updateQuestionData.bind(this);
    this.redirect = this.redirect.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRatingChange = this.handleRatingChange.bind(this);
    this.updateUserRecord = this.updateUserRecord.bind(this);
  }

  catcher = () => {
    this.setState( { isModalClosing: true } );

    this.t1 = setTimeout( () => this.setState( { isModalClosing: false } ), 1500);
  }

  openModal(answerId) {

    this.props.setModalsViewed( { [this.state.questionId]: true } );

    this.setState({ modalIsOpen: true, modal: this.state.question.children.find( (answer) => answer.id === answerId).modal },
      () => {
      /*
        this.t2 = setTimeout( () => {
          document.getElementById('modal-button').addEventListener('click', this.catcher);
        });
        */
      });
  }

  componentWillUnmount() {
    clearTimeout(this.t1);
   //  clearTimeout(this.t2);
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  getPrevLink() {
    return this.state.question.prev && this.state.question.prev !== -1 ? `/question/${this.state.question.prev}` : '/details';
  }

  getNextLink(answerId) {
    answerId = Number.isInteger(answerId) ? answerId : null;

    let children = this.state.question.children || [];

    let answer = (children.find( answer => answer.id === answerId) || []);

    let next = (answer && answer.next) || this.state.question.next || null;

    if (next && next !== -1) {
      return `/question/${next}`;
    }
    else {
      return '/profile';
    }
  }

  updateQuestionData(data) {
    this.setState({ question: data.question, numQuestions: data.numQuestions, questionType: data.questionType });
    this.props.setQuestionProgress( { current: this.state.questionId, total: this.state.numQuestions } );
  }

  redirect(link) {
    this.props.history.push(link);
  }

  handleCheckboxChange = name => event => {
    let checkboxValues = this.state.checkboxValues || {};

    const newValue = { [name]: event.target.checked };

    this.updateUserRecord({ ...checkboxValues, ...newValue }, true);

    this.setState( {
      checkboxValues: {...checkboxValues, ...newValue },
    });
  };

  handleInputChange = name => event => {
    let ratingValues = this.state.ratingValues || {};

    const newValue = { [name]: event.target.value };

    this.updateUserRecord({ ...ratingValues, ...newValue }, true);

    this.setState( {
      ratingValues: {...ratingValues, ...newValue },
    });
  };

  handleRatingChange = (ratingId, answerId) => {
    let inputValues = this.state.inputValues || {};

    const newValue = { [ratingId]: answerId };

    console.log(this.state.currentRatingQuestion, this.state.question.options.length)

    if (this.state.currentRatingQuestion !== this.state.question.options.length - 1) {
      this.updateUserRecord({ ...inputValues, ...newValue }, true);

      this.setState({
        inputValues: { ...inputValues, ...newValue },
        currentRatingQuestion: this.state.currentRatingQuestion + 1,
      });
    }
    else {
      this.updateUserRecord({ ...inputValues, ...newValue });
    }
  };

  updateUserRecord(data, skipRedirect) {
    let qId = this.state.questionId.toString();

    this.props.updateAnswers( { ...this.props.userData.answers, [qId]: data } );

    db
      .collection('users')
      .doc(this.props.userData.userDocRef)
      .update({
        answers: JSON.stringify({ ...this.props.userData.answers, [qId]: data }),
      })
      .then( () => {
      });

    if (!skipRedirect) {
      setTimeout( () => this.redirect(this.getNextLink(data)), pageTransitionTime);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        {/*<ReactBody className='modal-closing' if={this.state.isModalClosing} />*/}
        <Lettering charClass="l" tagName={"h1"} className={"fade question-title" + (this.state.question.smallTitle ? ' small-title' : '')} lineClass="line" wordClass={"word"}>{this.state.question.title || ''}</Lettering>

        {/*<h1>{this.state.question.title || ''}</h1>*/}

        { /*this.state.questionType === 'rating' && (
          <h2>{this.state.question.options[this.state.currentRatingQuestion].title}</h2>
        ) */}

        <div className={classes.answerContainer + ' ' + this.state.questionType}>

          <Graph
            userData={this.props.userData}
            questionId={this.state.questionId}
            updateQuestionData={this.updateQuestionData}
            getNextLink={this.getNextLink}
            onDone={this.updateUserRecord}
            onRatingChange={this.handleRatingChange}
            redirect={this.redirect}
            openModal={this.openModal}
            currentRatingId={this.state.currentRatingQuestion}
          />

          { this.state.questionType === 'checkbox' && (
            <Checkboxes
              answers={this.state.question.children}
              handleChange={this.handleCheckboxChange}
              checkboxValues={this.state.checkboxValues}
            />
          )}

          { this.state.questionType === 'input' && (
            <Input
              answers={this.state.question.children}
              handleChange={this.handleInputChange}
              inputValues={this.state.inputValues}
            />
          )}
        </div>
        <Nav
          prevLink={this.getPrevLink}
          nextLink={this.getNextLink()}
          fade={this.state.navFade}
          prevLabel={"Back"}
          nextLabel={this.state.questionType === 'checkbox' || this.state.questionType === 'input' ? "Next" : "Skip"}
        />

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Help"
          closeTimeoutMS={1000}
        >

          <h2>{this.state.modal.title}</h2>

          <div className="ModalContent" dangerouslySetInnerHTML={ { __html: this.state.modal.content } }/>

          <div className="ModalButton" id="modal-button" onClick={this.closeModal}>
            <span>Okay</span>
          </div>
        </Modal>
      </div>
    );
  }
}

Question.propTypes = {
  question: PropTypes.string,
};


const mapStateToProps = (state) => ({
  userData: state.app.userData,
  answers: state.app.answers,
});

const mapDispatchToProps = (dispatch) => ({
  updateAnswers: (answers) => dispatch(updateAnswersAction(answers)),
  setModalsViewed: (data) => dispatch(setModalsViewedAction(data)),
  setQuestionProgress: (data) => dispatch(setQuestionProgressAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Question));