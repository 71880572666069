import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    height: '80vh',
    marginTop: '20vh',
    fontFamily: 'serif',
    padding: '40px',
  },
});


export const pageTransitionTime = 1000;


class Input extends React.Component {
  render() {
    const { classes, answers, handleChange, inputValues = {} }  = this.props;

    let sortedAnswers = answers.sort( (a, b) => a.id > b.id ? 1 : -1 );

    return (
      <div className={classes.container}>
        {sortedAnswers.filter(answer => answer.type === 'primary').map(answer => (
          <div key={answer.id}>
            <TextField
              className={classes.label}
              name={answer.name}
              value={inputValues[answer.name]}
              label={answer.name}
              onChange={handleChange(answer.name)}
            /><br/>
          </div>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(Input);