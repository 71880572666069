import React from 'react'
import { withStyles } from '@material-ui/core/styles';


const styles = (theme) => ({

});

class Lettering extends React.Component {
  constructor(props) {
    super(props);

    this.getSpanElement = this.getSpanElement.bind(this);
    this.getWrappedChars = this.getWrappedChars.bind(this);

    this.state = {
      loaded: '',
    };

    this.timings = [
      1025,
      1050,
      1075,
      1100,
      1125,
      1150,
      1175,
      1200,
      1225,
      1250,
      1275,
      1300,
    ];

    this.delays = [
      50,
      100,
      150,
      200,
      250,
      300,
      350,
      400,
      450,
      500,
      550,
      600,
      650,
      700
    ]
  }

  componentDidMount() {
    setTimeout( () => this.setState( { loaded: ' loaded' } ), 50 );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.children !== prevProps.children) {
      this.setState({ loaded: '' });
      setTimeout(() => this.setState({ loaded: ' loaded' }), 50);
    }
  }



  getSpanElement(elem, className, index, spacer) {
    const key = `${className}-${index}`
    return <span key={key} className={`${className} ${key}`} style={ { transitionDelay: this.delays[Math.floor(Math.random() * this.delays.length)] + 'ms', transitionDuration: this.timings[Math.floor(Math.random() * this.timings.length)] + 'ms' } }>{elem}{spacer}</span>
  }

  getWrappedChars() {
    let lineIndex = 0, wordIndex = 0, charIndex = 0
    const { children, lineClass, wordClass, charClass } = this.props;
    const childrenArr = children.split('\\n');

    const lines = childrenArr.map(line => {
      lineIndex += 1
      const wordsArray = line.split(' ')
      const words = wordsArray.map(word => {
        wordIndex += 1
        const charsArray = word.split('')
        const chars = charsArray.map(char => {
          charIndex += 1
          return this.getSpanElement(char, charClass, charIndex)
        })
        return this.getSpanElement(chars, wordClass, wordIndex, ' ')
      })
      return this.getSpanElement(words, lineClass, lineIndex, ' ')
    })
    return lines
  }

  render() {
    const wrappedChars = this.getWrappedChars();

    const { loaded } = this.state;

    return <h1 className={this.props.className + loaded}>
      {wrappedChars}
    </h1>;
  }
}

export default withStyles(styles)(Lettering);