import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import questions from '../../assets/questions.json';
import firebase from '../../Firebase';


const db = firebase.firestore();


const styles = (theme) => ({
  answerContainer: {
    padding: '20px',
  },

  answer: {
    textAlign: 'left',
    marginBottom: '30px',

    '& h3': {
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontSize: '18px',
      margin: '0',
    },

    '& h4': {
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontSize: '18px',
      margin: '0',
    }
  },

  profileContainer: {
    height: '100vh',
    overflow: 'auto',
    paddingBottom: '60px',

    '& h1': {
      marginBottom: '0',
    }
  }
});

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      person: this.props.match.params.person || '',
    };
  }

  componentDidMount() {
    db
      .collection('users')
      .doc(this.props.userData.userDocRef)
      .update({
        completed: true
      });
  }

  render() {
    const { name, answers } = this.props.userData;
    const { classes } = this.props;

    return (
      <div className={classes.profileContainer}>
        <h1>{name}</h1>

        <h3 style={ { padding: '16px', marginBottom: '0' }}>Thank you for filling out Yours Truly. You can view profile by entering your PIN <a href={'/'} style={ { textDecoration: 'underline' }}>here</a>. <br /><br />Please speak to a Portable staff member for a demo of a hospital experience using this data.</h3>


        <div className={classes.answerContainer}>
          {questions.map( question => {
            const answerId = answers[question.id];

            if (answerId) {
              var answer;

              if (question.type === 'checkbox') {
                answer = Object.entries(answerId)
                  .filter(el => el[1] === true)
                  .map(el => el[0].toLowerCase())
                  .join(', ');

                answer = answer.charAt(0).toUpperCase() + answer.slice(1);
              }
              else if (question.type === 'input') {
                const regex = new RegExp('{(.*?)}', 'gm');

                answer = question.answerLabel.replace(regex, (match, p1) => answerId[p1] );
              }
              else if (question.type === 'rating') {
                return Object.entries(answerId).map( answer => {
                  return (
                    <Answer
                      classes={classes}
                      question={question.options[answer[0]].title}
                      answer={question.children.find(el => el.id === answer[1]).name}
                      key={question.id + '' + answer[0]}
                    />
                  );
                })
              }
              else {
                answer = (question.children.find(child => child.id === answerId) || []).pLabel;
              }


              return (
                <Answer
                  classes={classes}
                  question={question.pLabel}
                  answer={answer}
                  key={question.id}
                />
              );
            }
            else {
              return <span />;
            }
          })}
        </div>
      </div>
    );
  }
}

const Answer = (props) => {
  const { classes, question, answer } = props;

  return (
    <div className={classes.answer}>
      <h3>{answer}</h3>
      <h4>{question}</h4>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.app.userData,
});

const mapPropsToDispatch = (state) => ({
});

export default connect(mapStateToProps, mapPropsToDispatch)(withStyles(styles)(Profile));